import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromReducers from '../reducers';

export const getUserStore = createFeatureSelector('user');

export const getUserEntities = createSelector(
  getUserStore,
  fromReducers.user.userEntitySelectors.selectAll
);

export const getUser = createSelector(getUserEntities, entities => {
  return Object.values(entities)[0];
});
