import { Type } from '@angular/core';
export * from './reducers';
export * from './actions';
export * from './selectors';

import {
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer
} from '@ngrx/store';

import * as fromReducers from './reducers';


export interface State {
  idea: fromReducers.idea.State;
  filter: fromReducers.filter.State;
  user: fromReducers.user.State;
  newIdea: fromReducers.newIdea.State;
}

export const reducers: ActionReducerMap<State> = {
  idea: fromReducers.idea.reducer,
  filter: fromReducers.filter.reducer,
  user: fromReducers.user.reducer,
  newIdea: fromReducers.newIdea.reducer,
};

export const metaReducers: MetaReducer<State>[] = [];
