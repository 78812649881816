import { Action } from '@ngrx/store';
import { Filter } from '@appModels/index';

export enum FilterActionsTypes {
  ADD_CHECKBOX_FILTER = '[Filter] Add checkbox filter',
  ADD_SELECTOR_FILTER = '[Filter] Add selector filter',
  UPDATE_ITEM = '[Filter] Update Item',
  UPDATE_SELECTOR_FILTER = '[Filter] Update selector filter',
  GET_ITEM_SELECTOR_FILTER = '[Filter] Get item from selector filter',
  ADD_ITEM_SELECTOR_FILTER = '[Filter] Add item to selector filter',
  GET_ITEM_BY_ID = '[Filter] Get Item by id',
  UNCHECK_FILTER = '[Filter] Uncheck Item',
  CHECK_ITEM = '[Filter] Check Item',
  CHANGE_FILTER_STATE = '[Filter] Change Item state',
  CLEAR_FILTERS = '[Filter] Clear filters'
}

export class AddCheckboxFilter implements Action {
  readonly type = FilterActionsTypes.ADD_CHECKBOX_FILTER;

  constructor(public payload: Filter) {}
}

export class AddSelectorFilter implements Action {
  readonly type = FilterActionsTypes.ADD_SELECTOR_FILTER;

  constructor(public payload: Filter) {}
}

export class GetItemSelectorFilter implements Action {
  readonly type = FilterActionsTypes.GET_ITEM_SELECTOR_FILTER;

  constructor(public itemTitle, public filterId) {}
}

export class AddItemSelectorFilter implements Action {
  readonly type = FilterActionsTypes.ADD_ITEM_SELECTOR_FILTER;

  constructor(public itemTitle, public filterId) {}
}

export class UpdateFilter {
  readonly type = FilterActionsTypes.UPDATE_ITEM;

  constructor(public filterId, public facetTitle) {}
}

export class UncheckFilter {
  readonly type = FilterActionsTypes.UNCHECK_FILTER;
  constructor(public filterId) {}
}

export class GetFilterById implements Action {
  readonly type = FilterActionsTypes.GET_ITEM_BY_ID;
  constructor(public filterId) {}
}

export class CheckFilter implements Action {
  readonly type = FilterActionsTypes.CHECK_ITEM;
  constructor(public filterId, public facetId) {}
}

export class UpdateSelectorFilter implements Action {
  readonly type = FilterActionsTypes.UPDATE_SELECTOR_FILTER;
  constructor(public selectorFilterItems, public filterId) {}
}

export class ChangeFilterState implements Action {
  readonly type = FilterActionsTypes.CHANGE_FILTER_STATE;
  constructor(public filterId, public facetId, public state) {}
}

export class ClearFilter implements Action {
  readonly type = FilterActionsTypes.CLEAR_FILTERS;
  constructor(public payload: Filter) {}
}

export type FilterAction =
  | AddCheckboxFilter
  | AddSelectorFilter
  | GetItemSelectorFilter
  | AddItemSelectorFilter
  | UpdateFilter
  | UncheckFilter
  | UpdateSelectorFilter
  | GetFilterById
  | CheckFilter
  | ChangeFilterState
  | ClearFilter;

