import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromReducers from '../reducers';

export const getIdeaStore = createFeatureSelector('idea');

export const getIdeaEntities = createSelector(
  getIdeaStore,
  fromReducers.idea.ideaEntitySelectors.selectAll
);

export const getIdeas = createSelector(getIdeaEntities, entities => {
  return Object.values(entities);
});

export const getSearchIdeas = createSelector(
  getIdeaStore,
  (ideaStore: fromReducers.idea.State) => ideaStore.searchIdeas
);
