import {
  EntityAdapter,
  createEntityAdapter,
  EntityState,
  Update
} from '@ngrx/entity';

import {User} from '../../models';
import {UserAction, UserActionsTypes} from '../actions/user.actions';

export interface State extends EntityState<User> {
  loaded: boolean;
  loading: boolean;
  error: any;
}

export const adapter: EntityAdapter<User> = createEntityAdapter<User>();

export const initialState: State = adapter.getInitialState({
  loaded: false,
  loading: false,
  error: null,
});

export function reducer(state = initialState, action: UserAction): State {
  switch (action.type) {
    case UserActionsTypes.ADD_DATA:
      return adapter.addOne(action.payload, state);

    default:
      return state;
  }
}

export const userEntitySelectors = adapter.getSelectors();
