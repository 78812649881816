import { Component, OnInit, HostListener, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store, select } from '@ngrx/store';
import { debounceTime } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';

import { DataService } from '@appServices/index';
import {Filter, Idea} from '@appModels/index';
import * as fromStore from '@appStore/index';
import {AddIdea, ClearIdeas} from '@appStore/actions/idea.actions';
import {
  AddCheckboxFilter,
  UpdateSelectorFilter,
  GetFilterById,
  ClearFilter
} from '@appStore/actions/filter.actions';
import * as fromSelectors from '@appStore/selectors';
import { filtersConsts, ideaCategoriesConsts } from '@appConsts/index';
import {FormControl} from '@angular/forms';
import {Router} from '@angular/router';

import {User} from '@appModels/index';
import {routesConsts} from '@appConsts/index';
import {AService} from '@appServices/index';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit, OnDestroy {
  profile: any;
  profile_loaded = false;
  @ViewChild('searchInput', {static: false}) searchInput: ElementRef<HTMLInputElement>;
  DEFAULT_FILTERS = [{
    title: 'Status',
    type: 'checkbox',
    filterItems: [],
    id: 1
  }, {
    title: 'Milestone',
    type: 'checkbox',
    filterItems: [],
    id: 3
  }, {
    title: 'Projects',
    type: 'checkbox',
    filterItems: [],
    id: 4
  }, {
    title: 'Labels',
    type: 'checkbox',
    filterItems: [],
    id: 2
  }];

  ideas_loading = true;
  filters$: Observable<Filter[]>;
  ideas$: Observable<Idea[]>;
  notFound = false;
  searchedIdea = '';
  notFoundSearch = '';
  ideaCategoriesConsts = ideaCategoriesConsts;
  searchControl = new FormControl();
  searchCtrlSub: Subscription;

  constructor(private http: HttpClient,
              public router: Router,
              private store: Store<fromStore.State>,
              private dataService: DataService,
              private auth: AService) { }

  toEditProfile() {
    this.router.navigate([routesConsts.USER_PROFILE_EDIT]);
  }

  getProfileInfo(user_id) {
    this.dataService.checkUser(user_id).subscribe((updateData) => {});
  }


  ngOnInit() {
    this.auth.userProfile$.subscribe(profile => {
      if (profile !== null) {
        const user = profile.sub.split('|');
        this.dataService.checkUser(user[1]).subscribe(response => {
          this.profile = response;
          this.profile_loaded = true;

          this.getIdeas();
          this.initFilters();
          this.ideas$ = this.store.pipe(select(fromSelectors.getIdeas));
          this.filters$ = this.store.pipe(select(fromSelectors.getFilters));

          this.searchCtrlSub = this.searchControl.valueChanges.pipe(debounceTime(500)).subscribe(newValue => {
            this.searchProcess(newValue);
          });
        });
      }
    });
  }

  initFilters() {
    this.DEFAULT_FILTERS.forEach((filter) => {
        this.store.dispatch(new AddCheckboxFilter(filter));
    });
  }

  searchProcess(searchValue?) {
    this.searchedIdea = searchValue;
    this.notFoundSearch = searchValue;

    this.store.dispatch(new ClearIdeas());
    this.initFilters();
    this.getIdeas();
  }

  searchLinkClicked(categoryId) {
    const category = this.DEFAULT_FILTERS[2].filterItems.find((item) => {
        return item.id === categoryId;
    });

    this.searchedIdea = category.title;
    this.searchInput.nativeElement.value = category.title;
    this.searchProcess();
  }

  clearFilters() {
    this.DEFAULT_FILTERS.forEach((filter) => {
      filter.filterItems.forEach((item) => {
        item.checked = false;
      });
      this.store.dispatch(new ClearFilter(filter));
    });
  }

  getIdeas() {
    this.dataService.loadIdeas$(this.searchedIdea, false, this.profile.id).subscribe((data) => {
      this.clearFilters();
      if (data.found !== undefined && !data.found) {
        this.notFound = true;
      } else {
        this.notFound = false;
        data.items.forEach((idea: Idea) => {
          this.store.dispatch(new AddIdea(idea));
        });

        data.facets.forEach((facet) => {
          let items = facet.items;
          items.forEach((item) => {
            item['checked'] = false;
          });
          this.store.dispatch(new GetFilterById(facet.filter_id));
          this.store.dispatch(new UpdateSelectorFilter(facet.items, facet.filter_id));
        });

        this.ideas_loading = false;
      }
    });
  }

  ngOnDestroy() {
    this.store.dispatch(new ClearIdeas());
    this.clearFilters();
    this.searchCtrlSub.unsubscribe();
  }
}
