import { Component, OnInit, Input } from '@angular/core';
import { Router} from '@angular/router';

import { ideaStatusesConsts, ideaCategoriesConsts, routesConsts } from '@appConsts/index';
import { Idea } from '@appModels/index';

@Component({
  selector: 'app-idea',
  templateUrl: './idea.component.html',
  styleUrls: ['./idea.component.scss']
})
export class IdeaComponent implements OnInit {
  @Input() idea: Idea;
  @Input() isDetails = false;
  @Input() isModerate = false;
  ideaStatuses = ideaStatusesConsts;
  ideaCategories = ideaCategoriesConsts;
  constructor(private router: Router) { }

  // isProgress() {
  //   return this.idea.status.id === ideaStatusesConsts.NEW_FUNDED_REQUIRED
  //     || this.idea.status.id === ideaStatusesConsts.FUNDING
  //     || this.idea.status.id === ideaStatusesConsts.FUNDED_READY;
  // }

  // getProgress() {
  //   let progress = 0;
  //   if (this.idea.status.id === ideaStatusesConsts.FUNDING) {
  //     progress = this.idea.current_amount / this.idea.total_amount * 100;
  //   } else if (this.idea.status.id === ideaStatusesConsts.FUNDED_READY) {
  //     progress = 100;
  //   }
  //   return progress;
  // }

  // isFunded() {
  //   return this.idea.status.id === ideaStatusesConsts.FUNDED_READY;
  // }

  // isFunding() {
  //   return this.idea.status.id === ideaStatusesConsts.FUNDING;
  // }

  hexToRgb(hex) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }

  getContrast(hex){
    const rgb = this.hexToRgb(hex);
    const brightness = (rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000;

    let text_color = 'rgb(0, 0, 0)';
    if (brightness < 123) {
      text_color = 'rgb(255, 255, 255)';
    }
    return text_color;
  }

  showIdeaDetails(idea) {
    this.router.navigate(
      [this.isModerate ? routesConsts.IDEA_DETAILS_MODERATE : routesConsts.IDEA_DETAILS, idea.id]
    );
  }

  ngOnInit() {
  }

}
