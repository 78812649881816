import {Component, ElementRef, HostListener, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {FormControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatAutocomplete, MatAutocompleteSelectedEvent, MatChipInputEvent, MatAutocompleteTrigger} from '@angular/material';
import {select, Store} from '@ngrx/store';
import {Router} from '@angular/router';

import * as fromStore from '@appStore/index';
import {AddNewIdea, ClearNewIdea} from '@appStore/actions/new-idea.actions';
import * as fromSelectors from '@appStore/selectors';
import {DataService, AService} from '@appServices/index';
import {routesConsts} from '@appConsts/index';

import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import * as _moment from 'moment';

const moment = _moment;

@Component({
  selector: 'app-idea-new',
  templateUrl: './idea-new.component.html',
  styleUrls: ['./idea-new.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ],
})
export class IdeaNewComponent implements OnInit {
  dateStart = new FormControl();
  dateEnd = new FormControl();
  public ideaNewForm: FormGroup;
  public amountForm: FormGroup;
  minDate = new Date();

  constructor(
    private fb: FormBuilder,
    private store: Store<fromStore.State>,
    private dataService: DataService,
    public auth: AService,
    private router: Router) { }

  labels = [
    {id: 1, title: 'Desktop wallet'},
    {id: 2, title: 'Mobile wallets'},
    {id: 3, title: 'Web wallet'},
    {id: 4, title: 'Hardware wallet integration'},
    {id: 5, title: 'Block explorer'},
    {id: 6, title: 'Miner'},
    {id: 7, title: 'Node'},
    {id: 8, title: 'Support'},
    {id: 9, title: 'CLI wallet'},
  ];

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  tagsCtrl = new FormControl();
  filteredTags = [];
  tags = [];
  allTags = [];

  @ViewChild('tagsInput', {static: false}) tagsInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', {static: false}) matAutocomplete: MatAutocomplete;

  isLabelListVisible = false;
  selectedLabel = {id: 0, title: 'Choose any'};

  @HostListener('document:click', ['$event']) clickout(event) {
    if (this.isLabelListVisible) {
      this.isLabelListVisible = false;
    }

    // if (this.isNetworkListVisible) {
    //   this.isNetworkListVisible = false;
    // }
  }

  getRandomColor() {
    const color = Math.floor(0x1000000 * Math.random()).toString(16);
    return ('000000' + color).slice(-6);
  }

  add(event: MatChipInputEvent): void {
  // Add skill only when MatAutocomplete is not open
  // To make sure this does not conflict with OptionSelected Event
    const input = event.input;
    const value = event.value;

    // Add our skill
    if ((value || '').trim()) {
      this.tags.push({'name': value.trim(), 'color': this.getRandomColor()});
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.tagsCtrl.setValue(null);
  }

  remove(skill): void {
    const index = this.tags.indexOf(skill);

    if (skill.from_autocomplete !== undefined) {
      this.filteredTags.unshift(skill);
    }

    if (index >= 0) {
      this.tags.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const label = this.filteredTags.find((item) => {
      return item.name === event.option.viewValue;
    });

    const index = this.filteredTags.indexOf(label, 0);
    if (index >= 0) {
      this.filteredTags.splice(index, 1);
    }

    this.tags.push({'name': label.name, 'color': label.color, 'from_autocomplete': 1});
    this.tagsInput.nativeElement.value = '';
    this.tagsCtrl.setValue(null);
  }

  selectionMade(ev: Event, trigger: MatAutocompleteTrigger) {
    ev.stopPropagation();
    trigger.openPanel();
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allTags.filter(tag => tag.toLowerCase().indexOf(filterValue) === 0);
  }

  hexToRgb(hex) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }

  getContrast(hex){
    const rgb = this.hexToRgb(hex);
    const brightness = (rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000;

    let text_color = 'rgb(0, 0, 0)';
    if (brightness < 123) {
      text_color = 'rgb(255, 255, 255)';
    }
    return text_color;
  }

  showLabelOptions(event) {
    event.stopPropagation();
    // this.isNetworkListVisible = false;
    this.isLabelListVisible = !this.isLabelListVisible;
  }

  labelSelected(label) {
    this.selectedLabel = label;
    this.ideaNewForm.controls.label.setValue(label.id);
  }

  confirmIdea() {
    this.router.navigateByUrl(routesConsts.NEW_IDEA_CONFIRM);
  }

  ngOnInit() {
    this.dataService.loadLabels().subscribe((labels) => {
      this.filteredTags = labels;
    });

    this.amountForm = this.fb.group({
      amount: ['', Validators.required],
      currency: ['', Validators.required]
    });

    this.ideaNewForm = this.fb.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
    });

    this.ideaNewForm.addControl('amount',  this.amountForm);
    this.amountForm.controls.currency.setValue('beam');

    this.store.pipe(select(fromSelectors.getNewIdea)).subscribe((idea) => {
      if (idea) {
        this.ideaNewForm.controls.title.setValue(idea.title);
        this.ideaNewForm.controls.description.setValue(idea.description);
        this.tags = idea.label;

        this.amountForm.controls.amount.setValue(idea.reward.amount);
        this.amountForm.controls.currency.setValue(idea.reward.currency);

        this.dateStart.setValue(idea.timeline.start);
        this.dateEnd.setValue(idea.timeline.end);
      }
    });
  }

  public onSubmit(): void {
    if (this.ideaNewForm.valid) {
      this.auth.userProfile$.subscribe(profile => {
        if (profile !== null) {
          const userData = profile.sub.split('|');
          this.store.dispatch(new ClearNewIdea());
          this.store.dispatch(new AddNewIdea({
            github: '',
            title: this.ideaNewForm.value.title,
            label: this.tags,
            description: this.ideaNewForm.value.description,
            current_amount: 0,
            created_by: userData[1],
            reward: {
              amount: parseInt(this.amountForm.value.amount, 10),
              currency: this.amountForm.value.currency
            },
            timeline: {
              start: this.dateStart.value,
              end: this.dateEnd.value
            }
          }));
          this.confirmIdea();
        }
      });
      return;
    }
  }
}
