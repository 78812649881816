import { ActionReducerMap, createFeatureSelector, MemoizedSelector, MetaReducer } from '@ngrx/store';
import * as idea from './idea.reducer';
import * as newIdea from './new-idea.reducer';
import * as filter from './filter.reducer';
import * as user from './user.reducer';
import * as loader from './loader.reducer';

export { idea, filter, user, newIdea, loader };

export interface CoreState {
    loader: loader.LoaderState;
  }

  export const reducers: ActionReducerMap<CoreState> = {
    loader: loader.reducer
  };

  export const getLoaderState: MemoizedSelector<object, loader.LoaderState> = createFeatureSelector('loader');
