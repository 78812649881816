import { Action } from '@ngrx/store';
import { NewIdea } from '@appModels/index';

export enum NewIdeaActionsTypes {
  ADD_NEW_IDEA = '[NewIdea] Add New Idea',
  CLEAR_NEW_IDEA = '[NewIdea] Clear New Idea'
}

export class AddNewIdea implements Action {
  readonly type = NewIdeaActionsTypes.ADD_NEW_IDEA;
  constructor(public payload: NewIdea) {}
}

export class ClearNewIdea implements Action {
  readonly type = NewIdeaActionsTypes.CLEAR_NEW_IDEA;
}

export type NewIdeaActions =
  | AddNewIdea
  | ClearNewIdea;

