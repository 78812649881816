import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule} from '@appShared/shared.module';
import * as fromStore from '@appStore/index';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { MarkdownModule, MarkedOptions } from 'ngx-markdown';
import { DigitOnlyModule } from '@uiowa/digit-only';

import 'marked/lib/marked.js';
import 'prismjs/prism.js';

import {
    MatExpansionModule,
    MatDatepickerModule,
    MatButtonModule,
    MatCheckboxModule,
    MatIconModule,
    MatTabsModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatListModule,
    MatCardModule,
    MatProgressBarModule,
    MatTableModule,
    MatToolbarModule,
    MatInputModule,
    MatFormFieldModule,
    MatPaginatorModule,
    MatTooltipModule,
    MatSelectModule
} from '@angular/material';
import { HomepageComponent } from './homepage/homepage.component';
import { IdeaComponent } from './idea/idea.component';
import { IdeaDetailsComponent } from './idea-details/idea-details.component';
import { CallbackComponent } from './callback/callback.component';
import { UserModule } from './modules/user/user.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IdeaNewComponent } from './idea-new/idea-new.component';
import { IdeaNewConfirmComponent } from './idea-new-confirm/idea-new-confirm.component';
import { HowWorksComponent } from './how-works/how-works.component';
import { IdeaPlaceholderComponent } from './idea-placeholder/idea-placeholder.component';

@NgModule({
  declarations: [
    AppComponent,
    HomepageComponent,
    IdeaDetailsComponent,
    CallbackComponent,
    IdeaNewComponent,
    IdeaNewConfirmComponent,
    HowWorksComponent,
    IdeaPlaceholderComponent,
  ],
  imports: [
    BrowserModule,
    UserModule,
    SharedModule,
    BrowserAnimationsModule,
    HttpClientModule,
    // Material
    MatExpansionModule,
    MatDatepickerModule,
    MatSelectModule,
    MatButtonModule,
    MatCheckboxModule,
    MatIconModule,
    MatTabsModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatListModule,
    MatCardModule,
    MatProgressBarModule,
    MatTableModule,
    MatToolbarModule,
    MatInputModule,
    MatFormFieldModule,
    MatPaginatorModule,
    MatTooltipModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    DigitOnlyModule,
    MarkdownModule.forRoot({
      markedOptions: {
        provide: MarkedOptions,
        useValue: {
          gfm: true,
          tables: true,
          breaks: true,
          pedantic: false,
          sanitize: false,
          smartLists: true,
          smartypants: false,
        },
      },
    }),
    StoreModule.forRoot(fromStore.reducers),
    StoreDevtoolsModule.instrument({ maxAge: 50 }),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
