import { Component, OnInit, HostListener, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store, select } from '@ngrx/store';
import { debounceTime } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';

import { DataService } from '@appServices/index';
import {Filter, Idea} from '@appModels/index';
import * as fromStore from '@appStore/index';
import {AddIdea, ClearIdeas} from '@appStore/actions/idea.actions';
import {
  AddCheckboxFilter,
  UpdateSelectorFilter,
  GetFilterById,
  ClearFilter
} from '@appStore/actions/filter.actions';
import * as fromSelectors from '@appStore/selectors';
import { filtersConsts, ideaCategoriesConsts } from '@appConsts/index';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit, OnDestroy {
  @ViewChild('searchInput', {static: false}) searchInput: ElementRef<HTMLInputElement>;
  DEFAULT_FILTERS = [{
    title: 'Status',
    type: 'checkbox',
    filterItems: [],
    id: 1
  }, {
    title: 'Milestone',
    type: 'checkbox',
    filterItems: [],
    id: 3
  }, {
    title: 'Projects',
    type: 'checkbox',
    filterItems: [],
    id: 4
  }, {
    title: 'Labels',
    type: 'checkbox',
    filterItems: [],
    id: 2
  }];

  ideas_loading = true;
  sortIdeasBy = [
      {id: 1, title: 'Created: recently'},
      {id: 2, title: 'Created: oldest'},
      {id: 3, title: 'Completed: recently'},
      {id: 4, title: 'Completed: oldest'},
      {id: 5, title: 'Donated: recently'},
      {id: 6, title: 'Donated: oldest'},
  ];

  selectedSortBy = this.sortIdeasBy[0];
  isSortByVisible = false;
  filters$: Observable<Filter[]>;
  ideas$: Observable<Idea[]>;
  profile: any;
  notFound = false;
  searchedIdea = '';
  notFoundSearch = '';
  ideaCategoriesConsts = ideaCategoriesConsts;
  searchControl = new FormControl();
  searchCtrlSub: Subscription;

  @HostListener('document:click', ['$event']) clickout(event) {
    if (this.isSortByVisible) {
      this.isSortByVisible = false;
    }
  }

  constructor(private dataService: DataService,
              private http: HttpClient,
              private store: Store<fromStore.State>) { }

  showSortOptions(event) {
    event.stopPropagation();
    this.isSortByVisible = !this.isSortByVisible;
  }

  sortBySelected(sortBy) {
    console.log(sortBy.title);
  }

  initFilters() {
    this.DEFAULT_FILTERS.forEach((filter) => {
        this.store.dispatch(new AddCheckboxFilter(filter));
    });
  }

  searchProcess(searchValue?) {
    this.searchedIdea = searchValue;
    this.notFoundSearch = searchValue;

    this.store.dispatch(new ClearIdeas());
    this.initFilters();
    this.getIdeas();
  }

  searchLinkClicked(categoryId) {
    const category = this.DEFAULT_FILTERS[2].filterItems.find((item) => {
        return item.id === categoryId;
    });

    this.searchedIdea = category.title;
    this.searchInput.nativeElement.value = category.title;
    this.searchProcess();
  }

  clearFilters() {
    this.DEFAULT_FILTERS.forEach((filter) => {
      filter.filterItems.forEach((item) => {
        item.checked = false;
      });
      this.store.dispatch(new ClearFilter(filter));
    });
  }

  getIdeas() {
    this.dataService.loadIdeas$(this.searchedIdea).subscribe((data) => {
      this.clearFilters();
      if (!data.found) {
        this.notFound = true;
      } else {
        this.notFound = false;
        data.items.forEach((idea: Idea) => {
          this.store.dispatch(new AddIdea(idea));
        });

        data.facets.forEach((facet) => {
          let items = facet.items;
          items.forEach((item) => {
            item['checked'] = false;
          });
          this.store.dispatch(new GetFilterById(facet.filter_id));
          this.store.dispatch(new UpdateSelectorFilter(facet.items, facet.filter_id));
        });

        this.ideas_loading = false;
      }
    });
  }

  ngOnInit() {
    this.getIdeas();
    this.initFilters();
    this.ideas$ = this.store.pipe(select(fromSelectors.getIdeas));
    this.filters$ = this.store.pipe(select(fromSelectors.getFilters));

    this.searchCtrlSub = this.searchControl.valueChanges.pipe(debounceTime(500)).subscribe(newValue => {
      this.searchProcess(newValue);
    });
  }

  ngOnDestroy() {
    this.store.dispatch(new ClearIdeas());
    this.clearFilters();
    this.searchCtrlSub.unsubscribe();
  }
}
