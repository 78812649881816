import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserLayoutComponent } from './layouts/user-layout/user-layout.component';
import { UserEditComponent } from './pages/user-edit/user-edit.component';
import { UserProfileComponent } from './pages/user-profile/user-profile.component';
import { SharedModule } from '../../shared/shared.module';
import { UserRoutingModule } from './user-routing.module';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { MarkdownModule, MarkedOptions } from 'ngx-markdown';
import {
  MatFormFieldModule,
  MatInputModule,
  MatButtonModule,
  MatChipsModule,
  MatIconModule,
  MatAutocompleteModule,
  MatCheckboxModule
} from '@angular/material';

import { IdeaComponent } from '../../idea/idea.component';

import {TimeAgoPipe} from 'time-ago-pipe';
import { TruncatePipe } from 'app/pipes/truncate.pipe';

@NgModule({
  declarations: [
    UserLayoutComponent,
    UserEditComponent,
    UserProfileComponent,
    IdeaComponent,
    TimeAgoPipe,
    TruncatePipe
    ],
  imports: [
    CommonModule,
    SharedModule,
    UserRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    MatChipsModule,
    MatIconModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MarkdownModule.forRoot({
      markedOptions: {
        provide: MarkedOptions,
        useValue: {
          gfm: true,
          tables: true,
          breaks: true,
          pedantic: false,
          sanitize: false,
          smartLists: true,
          smartypants: false,
        },
      },
    }),
  ],
  exports: [
    IdeaComponent
  ]
})
export class UserModule { }
