import {
  EntityAdapter,
  createEntityAdapter,
  EntityState,
  Update
} from '@ngrx/entity';

import {Filter} from '@appModels/index';
import {FilterAction, FilterActionsTypes} from '../actions/filter.actions';

export interface State extends EntityState<Filter> {
  loaded: boolean;
  loading: boolean;
  error: any;
  selectedFilterId: number;
}

export const adapter: EntityAdapter<Filter> = createEntityAdapter<Filter>();

export const initialState: State = adapter.getInitialState({
  loaded: false,
  loading: false,
  selectedFilterId: null,
  error: null
});

export function reducer(state = initialState, action: FilterAction): State {
  switch (action.type) {
    case FilterActionsTypes.ADD_CHECKBOX_FILTER:
      return adapter.addOne(action.payload, state);

    case FilterActionsTypes.ADD_SELECTOR_FILTER:
        return adapter.addOne(action.payload, state);

    case FilterActionsTypes.UPDATE_ITEM: {
      const filterData = state.entities[action.filterId].filterItems.find((filter) => {
        return filter.id === parseInt(action.facetTitle, 10);
      });

      if (filterData) {
        filterData.checked = !filterData.checked;
      }
      return state;
    }

    case FilterActionsTypes.UNCHECK_FILTER: {
      state.entities[action.filterId].filterItems.forEach((filter) => {
        filter.checked = false;
      });

      return state;
    }

    case FilterActionsTypes.UPDATE_SELECTOR_FILTER: {
      state.entities[action.filterId].filterItems = action.selectorFilterItems;

      return state;
    }

    case FilterActionsTypes.GET_ITEM_SELECTOR_FILTER: {
      const selectorItemIndex = state.entities[action.filterId].filterItems.findIndex((filterItem) => {
        return filterItem.title === action.itemTitle;
      });

      state.entities[action.filterId].filterItems.splice(selectorItemIndex, 1);

      return state;
    }

    case FilterActionsTypes.ADD_ITEM_SELECTOR_FILTER: {
      state.entities[action.filterId].filterItems.push({'title': action.itemTitle});

      return state;
    }

    case FilterActionsTypes.CHECK_ITEM: {
      const filterData = state.entities[action.filterId].filterItems.find((filter) => {
        return filter.id === parseInt(action.facetId, 10);
      });

      if (filterData) {
        filterData.checked = true;
      }
      return state;
    }

    case FilterActionsTypes.CHANGE_FILTER_STATE: {
      const filterData = state.entities[action.filterId].filterItems.find((filter) => {
        return filter.id === action.facetId;
      });

      return state;
    }

    case FilterActionsTypes.GET_ITEM_BY_ID:
      return { ...state, selectedFilterId: action.filterId, loading: false };

    case FilterActionsTypes.CLEAR_FILTERS: {
      return adapter.updateOne(
        {
          id: action.payload.id,
          changes: action.payload
        },
        {
          ...state
        }
      );
    }

    default:
      return state;
  }
}

export const filterEntitySelectors = adapter.getSelectors();

