import { Component, OnInit } from '@angular/core';
import { ActivatedRoute} from '@angular/router';
import { Router} from '@angular/router';

import { DataService, AService } from '@appServices/index';
import { Idea, Status, IdeaUpdate } from '@appModels/index';
import { routesConsts } from '@appConsts/index';

@Component({
  selector: 'app-idea-details',
  templateUrl: './idea-details.component.html',
  styleUrls: ['./idea-details.component.scss']
})
export class IdeaDetailsComponent implements OnInit {
  idea: Idea;
  ideaLoaded = false;
  workOnLoaded = false;
  isParticipate = false;
  comments = [];
  commentsLoaded = false;

  constructor(private router: Router,
              private dataService: DataService,
              private route: ActivatedRoute,
              public auth: AService) { }

  navigateHome() {
      this.router.navigate(
          [routesConsts.HOME]
      );
  }

  updateIdeaStatus() {
    this.dataService.getIdeaStatus(this.idea.id).subscribe((updateData) => {
      this.idea.status = updateData.status;
      this.idea.working_on = updateData.participants;
    });
  }

  startWorking() {
    if (this.auth.loggedIn) {
      this.auth.userProfile$.subscribe((profile) => {
        const user = profile.sub.split('|');
        this.dataService.startWorking({'user_id': user[1], 'id': this.idea.id}).subscribe((updateData) => {
          this.isParticipate = true;
          this.updateIdeaStatus();
        });
      });
    } else {
      this.auth.login();
    }
  }

  stopWorking() {
    this.auth.userProfile$.subscribe((profile) => {
      const user = profile.sub.split('|');
      this.dataService.stopWorking({'user_id': user[1], 'id': this.idea.id}).subscribe((updateData) => {
        this.isParticipate = false;
        this.updateIdeaStatus();
      });
    });
  }

  hexToRgb(hex) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }

  getContrast(hex){
    const rgb = this.hexToRgb(hex);
    const brightness = (rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000;

    let text_color = 'rgb(0, 0, 0)';
    if (brightness < 123) {
      text_color = 'rgb(255, 255, 255)';
    }
    return text_color;
  }

  ngOnInit() {
    this.route.params.subscribe( (params) => {
      this.dataService.loadIdea(params.id).subscribe((idea) => {
        this.idea = idea;
        this.ideaLoaded = true;

        this.auth.userProfile$.subscribe((profile) => {
          if (profile !== null) {
            const user_id = profile.sub.split('|');
            const workOn = this.idea.working_on.find((user) => {
              return user.user_id === user_id[1];
            });

            this.isParticipate = !!workOn;
            this.workOnLoaded = true;
          }
        });

        this.dataService.loadIdeaEvents(idea.github_number).subscribe((data) => {
          this.comments = data.comments.concat(data.events);
          this.comments.forEach((comment) => {
            if (comment.event === 'labeled') {
              this.dataService.loadIdeaEvent(comment.id, comment.label.name).subscribe((event_label) => {
                if (event_label.found) {
                  comment.label.color = event_label.label.color;
                }
              });
            }

            if (comment.event === 'added_to_project') {
              this.dataService.loadIdeaProject(comment.project_card.project_id).subscribe((project) => {
                comment['project_name'] = project.name;
              });
            }
          });
          this.comments.sort(function (a, b) {
            if (a.created_at > b.created_at) {
              return 1;
            }
            if (a.created_at < b.created_at) {
              return -1;
            }

            return 0;
          });
          this.commentsLoaded = true;
        });
      });
    });
  }

}
