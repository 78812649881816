import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';

import {
  MatSelectModule,
  MatToolbarModule,
  MatInputModule,
} from '@angular/material';
import { FilterComponent } from './filter/filter.component';
import { AvatarCircleComponent } from './avatar-circle/avatar-circle.component';
import { LoaderComponent } from './loader/loader.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    FilterComponent,
    AvatarCircleComponent,
    LoaderComponent,
  ],
  imports: [
    CommonModule,
    MatSelectModule,
    MatToolbarModule,
    MatInputModule
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    FilterComponent,
    AvatarCircleComponent,
    LoaderComponent
  ]
})
export class SharedModule { }
