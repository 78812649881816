import {Component, Input, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';

import {filtersConsts} from '@appConsts/index';
import {DataService} from '@appServices/index';
import {AddIdea, ClearIdeas} from '@appStore/actions/idea.actions';
import {
  UpdateFilter,
  CheckFilter,
  UncheckFilter
} from '@appStore/actions/filter.actions';
import * as fromSelectors from '@appStore/selectors';
import * as fromStore from '@appStore/index';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {
  @Input() filters: any;
  @Input() user: any;
  @Input() search: string;
  selectedTags  = [];
  consts: any;
  loader = false;
  clickedFilterId = '';
  DEFAULT_FILTERS = [{
    title: 'Status',
    type: 'checkbox',
    filterItems: [],
    id: 1
  }, {
    title: 'Milestone',
    type: 'checkbox',
    filterItems: [],
    id: 3
  }, {
    title: 'Projects',
    type: 'checkbox',
    filterItems: [],
    id: 4
  }, {
    title: 'Labels',
    type: 'checkbox',
    filterItems: [],
    id: 2
  }];

  constructor(private dataService: DataService,
              private store: Store<fromStore.State>,
              private _route: ActivatedRoute,
              private _router: Router) { }

  ngOnInit() {
    this.consts = filtersConsts;

    this._route.queryParams.subscribe((params) => {
      this.updateFilters(params);
    });
  }

  updateFilters(params) {
    this.dataService.loadIdeas$(this.search, params, this.user).subscribe((data) => {
      this.store.dispatch(new ClearIdeas());
      data.items.forEach((idea) => {
        this.store.dispatch(new AddIdea(idea));
      });

      if (params.statuses !== undefined) {
        if (typeof params.statuses === 'string') {
          this.store.dispatch(new CheckFilter(filtersConsts.STATUS, params.statuses));
        } else {
          params.statuses.forEach((status) => {
            this.store.dispatch(new CheckFilter(filtersConsts.STATUS, status));
          });
        }
      }

      if (params.labels !== undefined) {
        if (typeof params.labels === 'string') {
          this.store.dispatch(new CheckFilter(filtersConsts.LABELS, params.labels));
        } else {
          params.labels.forEach((label) => {
            this.store.dispatch(new CheckFilter(filtersConsts.LABELS, label));
          });
        }
      }

      if (params.milestones !== undefined) {
        if (typeof params.milestones === 'string') {
          this.store.dispatch(new CheckFilter(filtersConsts.MILESTONES, params.milestones));
        } else {
          params.milestones.forEach((milestone) => {
            this.store.dispatch(new CheckFilter(filtersConsts.MILESTONES, milestone));
          });
        }
      }

      if (params.projects !== undefined) {
        if (typeof params.projects === 'string') {
          this.store.dispatch(new CheckFilter(filtersConsts.PROJECTS, params.projects));
        } else {
          params.projects.forEach((project) => {
            this.store.dispatch(new CheckFilter(filtersConsts.PROJECTS, project));
          });
        }
      }
      this.loader = false;
    });
  }

  constructFacets() {
    this.store.pipe(select(fromSelectors.getFilterEntities)).subscribe(filterItems => {
      const filterParams = {
        statuses: [],
        labels: [],
        milestones: [],
        projects: []
      };
      filterItems.forEach((filter) => {
        filter.filterItems.forEach((filterItem) => {
          if (filterItem.checked) {
            if (filter.id === filtersConsts.STATUS) {
              filterParams.statuses.push(filterItem.title);
            }

            if (filter.id === filtersConsts.LABELS) {
              filterParams.labels.push(filterItem.id);
            }

            if (filter.id === filtersConsts.MILESTONES) {
              filterParams.milestones.push(filterItem.id);
            }

            if (filter.id === filtersConsts.PROJECTS) {
              filterParams.projects.push(filterItem.id);
            }
          }
        });
      });
      this.addFilterParams(filterParams);
    });
  }

  addFilterParams(filterParams) {
    const params = {};
    if (filterParams.statuses.length > 0) {
      params['statuses'] = filterParams.statuses;
    }

    if (filterParams.projects.length > 0) {
      params['projects'] = filterParams.projects;
    }

    if (filterParams.labels.length > 0) {
      params['labels'] = filterParams.labels;
    }

    if (filterParams.milestones.length > 0) {
      params['milestones'] = filterParams.milestones;
    }

    this._router.navigate([], {
     relativeTo: this._route,
     queryParams: params
   });
  }

  resetFilter(id) {
    this.store.dispatch(new UncheckFilter(id));
    this.constructFacets();
  }

  filterItemClicked(filterId, itemId) {
    this.loader = true;
    this.clickedFilterId = itemId;
    this.store.dispatch(new UpdateFilter(filterId, itemId));
    this.constructFacets();
  }
}
