export enum routesConsts {
  HOME = '/',
  USER_PROFILE = '/user/profile',
  USER_PROFILE_EDIT = '/user/edit',
  NEW_IDEA = '/new-idea',
  NEW_IDEA_CONFIRM = '/new-idea-confirm',
  HOW_IT_WORKS = '/how-it-works',
  MODERATE = '/moderate',
  IDEA_DETAILS = '/idea',
  IDEA_DETAILS_MODERATE = '/idea-moderate'
}
