import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromReducers from '../reducers';

export const getFilterStore = createFeatureSelector('filter');

export const getFilterEntities = createSelector(
  getFilterStore,
  fromReducers.filter.filterEntitySelectors.selectAll
);

export const getFilters = createSelector(getFilterEntities, entities => {
  return Object.values(entities);
});


export const getSelectedFilterId = createSelector(
  getFilterStore,
  (filterStore: fromReducers.filter.State) => filterStore.selectedFilterId
);


export const getFilterById = createSelector(
  getFilterEntities,
  getSelectedFilterId,
  (entities, id) => entities.find(i => i.id === id)
);
