import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import {HomepageComponent} from './homepage/homepage.component';
import {IdeaDetailsComponent} from './idea-details/idea-details.component';
import {CallbackComponent} from './callback/callback.component';
import {IdeaNewComponent} from './idea-new/idea-new.component';
import { AuthGuard } from './auth.guard';
import {IdeaNewConfirmComponent} from './idea-new-confirm/idea-new-confirm.component';
import { HowWorksComponent } from './how-works/how-works.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptorService } from '@appServices/index';

const routes: Routes = [{
  path: '',
  component: HomepageComponent,
  pathMatch: 'full'
}, {
  path: 'callback',
  component: CallbackComponent
}, {
  path: 'new-idea',
  component: IdeaNewComponent,
  // canActivate: [AuthGuard]
}, {
  path: 'how-it-works',
  component: HowWorksComponent,
}, {
  path: 'new-idea-confirm',
  component: IdeaNewConfirmComponent,
  canActivate: [AuthGuard]
}, {
  path: 'idea/:id',
  component: IdeaDetailsComponent
}, {
  path: 'user',
  loadChildren: './modules/user/user.module#UserModule',
  canActivate: [AuthGuard]
}, {
  path: '**',
  redirectTo: ''
}];

@NgModule({
  imports: [RouterModule.forRoot(routes, /*{preloadingStrategy: PreloadAllModules}*/)],
  exports: [RouterModule],
  providers: [{
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
  }]
})
export class AppRoutingModule { }
