import { Action } from '@ngrx/store';
import { User } from '@appModels/index';

export enum UserActionsTypes {
  ADD_DATA = '[User] Add',
}

export class AddUser implements Action {
  readonly type = UserActionsTypes.ADD_DATA;

  constructor(public payload: User) {}
}

export type UserAction = AddUser;
