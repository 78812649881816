import {
  EntityAdapter,
  createEntityAdapter,
  EntityState,
  Update
} from '@ngrx/entity';

import { Idea } from '@appModels/index';
import {IdeaActions, IdeaActionsTypes} from '../actions/idea.actions';

export interface State extends EntityState<Idea> {
  loaded: boolean;
  loading: boolean;
  error: any;
  selectedIdeaId: number;
  searchIdeas: Idea[];
}

export const adapter: EntityAdapter<Idea> = createEntityAdapter<Idea>();

export const initialState: State = adapter.getInitialState({
  loaded: false,
  loading: false,
  selectedIdeaId: null,
  error: null,
  searchTerm: '',
  searchIdeas: null
});

export function reducer(state = initialState, action: IdeaActions): State {
  switch (action.type) {
    case IdeaActionsTypes.ADD_IDEA:
      return adapter.addOne(action.payload, state);

    case IdeaActionsTypes.CLEAR_IDEAS: {
      return adapter.removeAll({ ...state, selectedIdeaId: null });
    }

    default:
      return state;
  }
}

export const ideaEntitySelectors = adapter.getSelectors();
