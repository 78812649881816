import {Component, HostListener, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';

import {DataService, AService} from '@appServices/index';
import {routesConsts} from '@appConsts/index';
import {User} from '@appModels/index';
import * as fromStore from '@appStore/index';
import { Subscription } from 'rxjs';
import { Location } from '@angular/common';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  options = [{
    id: 0, title: 'How to Propose an idea'
  }, {
    id: 1, title: 'Contributor'
  }, {
    id: 2, title: 'Funder'
  }, {
    id: 3, title: 'Statuses'
  }];

  profile: any;
  profile_loaded = false;
  notHomepage = true;
  userData: User = {
    'user_id': null,
    'nickname': null,
    'firstname': null,
    'lastname': null,
    'personal_page': null,
    'email': null,
    'dev_level': null,
    'github': null,
    'picture': null,
    'beam_wallet': null,
    'github_sync': null,
    'created_ideas': 0,
    'completed_ideas': 0,
    'participated_in': 0,
    'funded_ideas': 0,
    'beam_collected': 0,
    'beam_funded': 0,
    'funded_state': 0,
    'collected_state': 0,
    'collected': 0,
    'funded': 0
  };
  SIGN_OUT_ID = 1;
  userMenuItems = [{
    id: 2,
    title: 'Profile',
    route: routesConsts.USER_PROFILE
  }, {
    id: 4,
    title: 'Sign out',
    route: routesConsts.HOME
  }];

  isMenuVisible = false;
  menuSelectedItem = false;
  userDataLoaded = false;
  isAuthenticated = false;
  userSub: Subscription;

  private auth0Client: Auth0Client;

  @HostListener('document:click', ['$event']) clickout(event) {
    if (this.isMenuVisible) {
      this.isMenuVisible = false;
    }
  }

  constructor(private router: Router,
              private dataService: DataService,
              public auth: AService,
              private store: Store<fromStore.State>,
              location: Location) {}

  proposeAnIdea() {
    this.router.navigate([routesConsts.NEW_IDEA]);
  }

  howItWorks() {
    this.router.navigate([routesConsts.HOW_IT_WORKS]);
  }

  showOptions() {

  }

  itemSelected() {

  }

  navigateHome() {
    this.router.navigate([routesConsts.HOME]);
  }

  navigateNewIdea() {
    this.router.navigate([routesConsts.NEW_IDEA]);
  }

  showMenuOptions(event) {
    event.stopPropagation();
    this.isMenuVisible = !this.isMenuVisible;
  }

  menuSelected(item) {
    if (item.id === this.userMenuItems[this.SIGN_OUT_ID].id) {
      this.auth.logout();
      this.menuSelectedItem = false;
    } else {
      this.router.navigate(
          [item.route]
      );
      this.menuSelectedItem = item;
    }
  }

  ngOnInit() {
    this.auth.localAuthSetup();
    this.userSub = this.auth.userProfile$.subscribe((profile) => {
      if (profile !== null) {
        const user = profile.sub.split('|');
        const userName = profile.name.split(' ');

        this.dataService.checkUser(user[1]).subscribe(response => {
          this.userData = <User>response;
          this.userDataLoaded = true;
         }, error => {
          this.userData.user_id = user[1];
          this.userData.nickname = profile.nickname;
          this.userData.firstname = userName[0];
          this.userData.lastname = userName[1];
          this.userData.github = user[0] === 'github' ? profile.nickname : null;
          this.userData.picture = profile.picture;
          this.userData.github_sync = user[0] === 'github' ? 1 : 0;
          this.userDataLoaded = true;
          this.dataService.addUser(this.userData).subscribe(response => {
            this.userData['is_moderator'] = response['is_moderator'];
          });
        });
      }
    });
  }
}
