export enum ideaCategoriesConsts {
  DESKTOP_WALLET = 1,
  MOBILE_WALLET = 2,
  WEB_WALLET = 3,
  HARDWARE_WALLET = 4,
  BLOCK_EXPLORER = 5,
  MINER = 6,
  NODE = 7,
  SUPPORT = 8,
  CLI_WALLET = 9
}
