import { Pipe, PipeTransform } from '@angular/core';
 
@Pipe({
    name: 'truncate'
})
export class TruncatePipe implements PipeTransform {
  transform(value: string, length: number, separator: string): string {
    separator = separator || '...';

    return value.substr(0, length) + separator + value.substr(value.length - length);
  }
}
