import { Component, OnInit, OnDestroy } from '@angular/core';
import * as fromSelectors from '../store/selectors';
import {Store, select} from '@ngrx/store';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';

import * as fromStore from '@appStore/index';
import {NewIdea} from '@appModels/index';
import {AddNewIdea, ClearNewIdea} from '@appStore/actions/new-idea.actions';
import {routesConsts} from '@appConsts/index';
import {DataService} from '@appServices/index';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-idea-new-confirm',
  templateUrl: './idea-new-confirm.component.html',
  styleUrls: ['./idea-new-confirm.component.scss']
})
export class IdeaNewConfirmComponent implements OnInit, OnDestroy {
  newIdea$: Observable<NewIdea>;
  creatingProcess = false;
  ideaSub: Subscription;
  createIdeaSub: Subscription;
  constructor(
    private router: Router,
    private store: Store<fromStore.State>,
    private dataService: DataService) { }

  ngOnInit() {
    this.newIdea$ = this.store.pipe(select(fromSelectors.getNewIdea));
  }

  backToEdit() {
    this.router.navigate([routesConsts.NEW_IDEA]);
    return;
  }

  createIdeaByService(idea) {
    this.creatingProcess = true;
    idea['tags'] = [];
    this.createIdeaSub = this.dataService.createIdea(idea).subscribe((updateData) => {
      this.store.dispatch(new ClearNewIdea());
      setTimeout(() => {
        this.router.navigate([routesConsts.HOME]);
      }, 10000);
    });
  }

  createIdea() {
    if (!this.creatingProcess) {
      this.ideaSub = this.newIdea$.subscribe((item) => {
        if (item) {
          this.createIdeaByService(item);
        }
      });
    }
    return;
  }

  ngOnDestroy() {
    if (this.ideaSub) {
      this.ideaSub.unsubscribe();
      this.createIdeaSub.unsubscribe();
    }
  }
}
