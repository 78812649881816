import { LoaderActionsTypes, LoaderActions } from '../actions/loader.actions';

export interface LoaderState {
  isVisible: boolean;
}

const initialState: LoaderState = {
  isVisible: false,
};

export function reducer(state: LoaderState = initialState, action: LoaderActions): LoaderState {
  switch (action.type) {
    case LoaderActionsTypes.ShowLoader: {
      return {
        ...state,
        isVisible: true,
      };
    }
    case LoaderActionsTypes.HideLoader: {
      return {
        ...state,
        isVisible: false,
      };
    }

    default: {
      return state;
    }
  }
}

export const getIsVisible = (state: LoaderState) => state.isVisible;
