import {Component, OnInit, ElementRef, ViewChild, HostListener} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {Router} from '@angular/router';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {Observable} from 'rxjs';
import {map, startWith, tap} from 'rxjs/operators';
import {Store, select} from '@ngrx/store';

import {MatAutocompleteSelectedEvent, MatAutocomplete} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';

import { DataService, AService } from '@appServices/index';
import {User} from '@appModels/index';
import * as fromStore from '@appStore/index';
import {routesConsts} from '@appConsts/index';
import * as fromSelectors from '@appStore/selectors';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent implements OnInit {
  public editForm: FormGroup;
  isDevLevelsVisible = false;

  devLevels = [{
    id: 1,
    title: 'Junior',
  }, {
    id: 2,
    title: 'Middle',
  }, {
    id: 3,
    title: 'Senior',
  }];

  defaultDevLevel = {
    id: 0,
    title: 'Choose the level'
  };
  userData: User = {
    'user_id': null,
    'nickname': null,
    'firstname': null,
    'lastname': null,
    'personal_page': null,
    'email': null,
    'dev_level': null,
    'github': null,
    'picture': null,
    'beam_wallet': null,
    'github_sync': null,
    'created_ideas': 0,
    'completed_ideas': 0,
    'participated_in': 0,
    'funded_ideas': 0,
    'beam_collected': 0,
    'beam_funded': 0,
    'collected_state': 0,
    'funded_state': 0,
    'funded': 0,
    'collected': 0
  };
  isGithubSynced = false;
  selectedDevLevel = this.defaultDevLevel;

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  skillsCtrl = new FormControl();
  filteredSkills: Observable<string[]>;
  skills: string[] = [];
  allSkills: string[] = ['Python', 'C++', 'JavaScript', 'AngularJS', 'NodeJs'];
  profile_loaded = false;

  @ViewChild('skillsInput', {static: false}) skillsInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', {static: false}) matAutocomplete: MatAutocomplete;

  @HostListener('document:click', ['$event']) clickout(event) {
    if (this.isDevLevelsVisible) {
      this.isDevLevelsVisible = false;
    }
  }

  constructor(private dataService: DataService,
              private auth: AService,
              private store: Store<fromStore.State>,
              public router: Router) {
    this.filteredSkills = this.skillsCtrl.valueChanges.pipe(
        startWith(null),
        map((skill: string | null) => skill ? this._filter(skill) : this.allSkills.slice()));
  }

  add(event: MatChipInputEvent): void {
    // Add skill only when MatAutocomplete is not open
    // To make sure this does not conflict with OptionSelected Event
    if (!this.matAutocomplete.isOpen) {
      const input = event.input;
      const value = event.value;

      // Add our skill
      if ((value || '').trim()) {
        this.skills.push(value.trim());
      }

      // Reset the input value
      if (input) {
        input.value = '';
      }

      this.skillsCtrl.setValue(null);
    }
  }

  remove(skill: string): void {
    const index = this.skills.indexOf(skill);

    if (index >= 0) {
      this.skills.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.skills.push(event.option.viewValue);
    this.skillsInput.nativeElement.value = '';
    this.skillsCtrl.setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allSkills.filter(skill => skill.toLowerCase().indexOf(filterValue) === 0);
  }

  ngOnInit() {
    this.editForm = new FormGroup({
      'firstname': new FormControl('', ),
      'lastname': new FormControl('', ),
      'nickname': new FormControl('', ),
      // 'email': new FormControl('', [Validators.email]),
      // 'github': new FormControl('', ),
      // 'personal_page': new FormControl('', ),
      // 'beam_wallet': new FormControl('', ),
      // 'github_sync': new FormControl(0, ),
      // 'collected_state': new FormControl(0, ),
      // 'funded_state': new FormControl(0, )
    });

    this.auth.userProfile$.subscribe(
      profile => {
        if (profile !== null) {
          const user = profile.sub.split('|');
          this.dataService.checkUser(user[1]).subscribe(response => {
            this.editForm.patchValue(response);

            this.userData = response;
          });
        }
      }
    );

    // this.editForm.get('github_sync').valueChanges.subscribe(state => {
    //   if (state) {
    //     this.editForm.get('firstname').disable();
    //     this.editForm.get('lastname').disable();
    //     this.editForm.get('nickname').disable();
    //     this.editForm.get('github').disable();
    //   } else {
    //     this.editForm.get('firstname').enable();
    //     this.editForm.get('lastname').enable();
    //     this.editForm.get('nickname').enable();
    //     this.editForm.get('github').enable();
    //   }
    // });
  }

  showDevelopingLevels(event) {
    event.stopPropagation();
    this.isDevLevelsVisible = !this.isDevLevelsVisible;
  }

  devLevelSelected(devLevel) {
    console.log('level selected');
  }

  backToProfile() {
    this.router.navigate([routesConsts.USER_PROFILE]);
  }

  updateUser() {
    // this.editForm.get('firstname').enable();
    // this.editForm.get('lastname').enable();
    // this.editForm.get('nickname').enable();
    // this.editForm.get('github').enable();

    this.userData['nickname'] = this.editForm.value.nickname;
    this.userData['firstname'] = this.editForm.value.firstname;
    this.userData['lastname'] = this.editForm.value.lastname;
    // this.userData['email'] = this.editForm.value.email;
    // this.userData['github'] = this.editForm.value.github;
    // this.userData['beam_wallet'] = this.editForm.value.beam_wallet;
    // this.userData['github_sync'] = this.editForm.value.github_sync ? 1 : 0;
    // this.userData['funded_state'] = this.editForm.value.funded_state ? 1 : 0;
    // this.userData['collected_state'] = this.editForm.value.collected_state ? 1 : 0;

    // if (this.editForm.value.github_sync) {
    //   this.editForm.get('firstname').disable();
    //   this.editForm.get('lastname').disable();
    //   this.editForm.get('nickname').disable();
    //   this.editForm.get('github').disable();
    // }

    this.dataService.updateUser(this.userData).subscribe((updateData) => {
      this.backToProfile();
    });
  }

  public onSubmit(): void {
    this.updateUser();
  //   if (this.signUpForm.valid) {
  //     this.store.dispatch(new SignupActions.Signup(this.signUpForm.value));
  //
  //     return;
  //   }
  }
}
