export enum ideaStatusesConsts {
  SUBMITTED = 1,
  APPROVED = 2,
  IN_PROGRESS = 3,
  CANCELED = 4,
  CLOSED = 5,
  COMPLETED_TESTNET = 7,
  COMPLETED_MAINNET = 8,
  COMPLETED = 10
}
