import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-avatar-circle',
  templateUrl: './avatar-circle.component.html',
  styleUrls: ['./avatar-circle.component.scss']
})
export class AvatarCircleComponent implements OnInit {
  @Input() src: string;
  constructor() { }

  ngOnInit() {
  }

}
