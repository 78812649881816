import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Idea } from '../models';
import { Observable } from 'rxjs';
import { AService } from './a.service';


@Injectable({
  providedIn: 'root'
})
export class DataService {

   API_BASE = 'https://community-api.beam.mw';
  // API_BASE = 'http://127.0.0.1:8000';

  constructor(private http: HttpClient,
              public authService: AService) {}

  loadIdeas$(searchedIdea = '', qParams?, user_id?): Observable<any> {
    let params = new HttpParams();

    if (qParams !== undefined) {
      if (qParams.statuses !== undefined) {
        if (typeof qParams.statuses === 'string') {
          params = params.append(`status[]`, qParams.labels);
        } else {
          qParams.statuses.forEach((status) => {
            params = params.append(`status[]`, status);
          });
        }
      }

      if (qParams.labels !== undefined) {
        if (typeof qParams.labels === 'string') {
          params = params.append(`label[]`, qParams.labels);
        } else {
          qParams.labels.forEach((label) => {
            params = params.append(`label[]`, label);
          });
        }
      }

      if (qParams.milestones !== undefined) {
        if (typeof qParams.milestones === 'string') {
          params = params.append(`mil[]`, qParams.labels);
        } else {
          qParams.milestones.forEach((milestone) => {
            params = params.append(`mil[]`, milestone);
          });
        }
      }

      if (qParams.projects !== undefined) {
        if (typeof qParams.projects === 'string') {
          params = params.append(`proj[]`, qParams.labels);
        } else {
          qParams.projects.forEach((project) => {
            params = params.append(`proj[]`, project);
          });
        }
      }
    }

    params = params.append(`search`, searchedIdea);
    if (user_id) {
      params = params.append(`user`, user_id);
    }

    return this.http.get<any>(this.API_BASE + '/ideas/', {params: params});
  }

  loadIdeasForModerate$(searchedIdea = '', statuses, categories, tags): Observable<any> {
    let params = new HttpParams();

    statuses.forEach((status) => {
      params = params.append(`status[]`, status);
    });

    categories.forEach((category) => {
      params = params.append(`category[]`, category);
    });

    tags.forEach((tag) => {
      params = params.append(`tag[]`, tag);
    });
    params = params.append(`search`, searchedIdea);

    return this.http.get<any>(this.API_BASE + '/ideas_for_moderate/', {params: params});
  }

  loadIdea(id) {
    return this.http.get<Idea>(this.API_BASE + '/idea/?id=' + id);
  }

  addUser(data): Observable<any>{
    return this.http.post(`${this.API_BASE}/user_data/`, JSON.stringify(data));
  }

  updateUser(data): Observable<any> {
    return this.http.put(`${this.API_BASE}/user_data/`, JSON.stringify(data));
  }

  checkUser(user_id): Observable<any> {
      return this.http.get(`${this.API_BASE}/user_check/?user_id=` + user_id);
  }

  createIdea(data): Observable<any> {
    return this.http.post(`${this.API_BASE}/create_idea/`, JSON.stringify(data));
  }

  addIdeaToGithub(data): Observable<any> {
    return this.http.post(`${this.API_BASE}/idea_to_github/`, JSON.stringify(data));
  }

  startWorking(data): Observable<any> {
    return this.http.post(`${this.API_BASE}/start_working/`, JSON.stringify(data));
  }

  stopWorking(data): Observable<any> {
    return this.http.post(`${this.API_BASE}/stop_working/`, JSON.stringify(data));
  }

  moderateIdea(data): Observable<any> {
    return this.http.post(`${this.API_BASE}/idea_moderate/`, JSON.stringify(data));
  }

  getIdeaStatus(id): Observable<any> {
    return this.http.get(`${this.API_BASE}/get_idea_status/?id=` + id);
  }

  createGthIdea(data): Observable<any> {
    return this.http.post(`${this.API_BASE}/create_gth_idea/`, JSON.stringify(data));
  }

  test(id): Observable<any> {
    return this.http.get(`https://dev-4m1i-m4u.auth0.com/api/v2/users`);
  }

  loadLabels(): Observable<any> {
    return this.http.get(this.API_BASE + '/get_labels/');
  }

  loadIdeaEvents(issue): Observable<any> {
    return this.http.get(this.API_BASE + '/get_issue_events/?issue=' + issue);
  }

  loadIdeaEvent(id, name): Observable<any> {
    return this.http.get(this.API_BASE + '/get_issue_event/?id=' + id + '&name=' + name);
  }

  loadIdeaProject(id): Observable<any> {
    return this.http.get(this.API_BASE + '/get_issue_project/?id=' + id);
  }  
}
