import { Component, OnInit } from '@angular/core';
import { NgForm} from '@angular/forms';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  constructor() { }

  passed = !!localStorage.getItem('passed');

  ngOnInit() {
  }

  onSubmit(form: NgForm) {
    if (form.value.login === 'beam' && form.value.password === 'meab') {
      localStorage.setItem('passed', 'true');
      this.passed = true;
    }

    console.log(form);
  }
}
