import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { UserEditComponent } from './pages/user-edit/user-edit.component';
import { UserLayoutComponent } from './layouts/user-layout/user-layout.component';
import { UserProfileComponent } from './pages/user-profile/user-profile.component';
import { AuthGuard } from '../../auth.guard';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptorService } from '@appServices/index';


const routes: Routes = [{
  path: 'user',
  component: UserLayoutComponent,
  children: [{
    path: 'profile',
    component: UserProfileComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard]
  }, {
    path: 'edit',
    component: UserEditComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard]
  }]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: InterceptorService,
    multi: true
  }]
})
export class UserRoutingModule {
  constructor() {

  }

}
