import { Action } from '@ngrx/store';
import { Idea } from '@appModels/index';

export enum IdeaActionsTypes {
  ADD_IDEA = '[Idea] Add Idea',
  CLEAR_IDEAS = '[Idea] Clear Ideas',
}

export class AddIdea implements Action {
  readonly type = IdeaActionsTypes.ADD_IDEA;
  constructor(public payload: Idea) {}
}

export class ClearIdeas implements Action {
  readonly type = IdeaActionsTypes.CLEAR_IDEAS;
}

export type IdeaActions =
  | AddIdea
  | ClearIdeas;

