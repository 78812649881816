import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';

import {routesConsts} from '@appConsts/index';

@Component({
  selector: 'app-how-works',
  templateUrl: './how-works.component.html',
  styleUrls: ['./how-works.component.scss']
})
export class HowWorksComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

  toCreate() {
    this.router.navigate([routesConsts.NEW_IDEA]);
  }
}
