import { Component, OnInit } from '@angular/core';
import {AService} from '@appServices/index';
import { Router } from '@angular/router';


@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss']
})
export class CallbackComponent implements OnInit {

  constructor(private router: Router,
              private auth: AService) {
  }

  ngOnInit() {
    this.auth.handleAuthCallback();
  }
}
