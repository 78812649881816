import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromReducers from '../reducers';

export const getNewIdeaStore = createFeatureSelector('newIdea');

export const getNewIdeaEntities = createSelector(
  getNewIdeaStore,
  fromReducers.newIdea.newIdeaEntitySelectors.selectAll
);

export const getNewIdea = createSelector(getNewIdeaEntities, entities => {
  return Object.values(entities)[0];
});

