import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

//import * as fromCore from '../../store';
@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, OnDestroy {
  // @HostBinding('class.loader') klass: boolean = true;
  // @HostBinding('class.loader--visible') isVisible: boolean = true;

  // private isLoaderVisible$: Subscription;

  // constructor(private store: Store<fromCore.CoreState>) {}

  public ngOnInit(): void {
    // this.isLoaderVisible$ = this.store
    //   .pipe(select(fromCore.isLoaderVisible))
    //   .subscribe((status: boolean) => {
    //     this.isVisible = status;
    //   });
  }

  public ngOnDestroy(): void {
  //  this.isLoaderVisible$.unsubscribe();
  }
}
