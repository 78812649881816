import {
  EntityAdapter,
  createEntityAdapter,
  EntityState,
  Update
} from '@ngrx/entity';

import { NewIdea } from '@appModels/index';
import {NewIdeaActions, NewIdeaActionsTypes} from '../actions/new-idea.actions';

export interface State extends EntityState<NewIdea> {
  loaded: boolean;
  loading: boolean;
  error: any;
}

export const adapter: EntityAdapter<NewIdea> = createEntityAdapter<NewIdea>();

export const initialState: State = adapter.getInitialState({
  loaded: false,
  loading: false,
  error: null,
});

export function reducer(state = initialState, action: NewIdeaActions): State {
  switch (action.type) {
    case NewIdeaActionsTypes.ADD_NEW_IDEA:
      return adapter.addOne(action.payload, state);

    case NewIdeaActionsTypes.CLEAR_NEW_IDEA:
      return adapter.removeAll({ ...state, selectedIdeaId: null });

    default:
      return state;
  }
}

export const newIdeaEntitySelectors = adapter.getSelectors();
