import { Action } from '@ngrx/store';

export enum LoaderActionsTypes {
  ShowLoader = '[Core] Show loader',
  HideLoader = '[Core] Hide loader',
}

export class ShowLoader implements Action {
  readonly type: string = LoaderActionsTypes.ShowLoader;
}

export class HideLoader implements Action {
  readonly type: string = LoaderActionsTypes.HideLoader;
}

export type LoaderActions =
  | ShowLoader
  | HideLoader;
