import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-idea-placeholder',
  templateUrl: './idea-placeholder.component.html',
  styleUrls: ['./idea-placeholder.component.scss']
})
export class IdeaPlaceholderComponent implements OnInit {

  labelsCount = this.getRandomInt(1, 5);
  items = [];

  constructor() { }

  getRandomColor() {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min;
  }

  ngOnInit() {
    let i = 0;
    while (i <= this.labelsCount) {
      this.items.push({'color': this.getRandomColor(), 'width': this.getRandomInt(62, 90)});
      i++;
    }
  }
}
